export interface ProductData {
  image: string;
  name: string;
  description?: string;
  video?: boolean;
}

export const products: ProductData[] = [
  { image: "eisenbahn-mit-osterhase", name: "Eisenbahn mit Osterhase", video: true },
  { image: "eisenbahn", name: "Eisenbahn", video: true },
  { image: "fabrikuhr", name: "Fabrikuhr" },
  { image: "katze", name: "Katze" },
  { image: "moewen", name: "Möwen", description: "Höhe: 17,5 cm" },
  { image: "pyramide-gross-weihnachtsmann-blau", name: "Große Pyramide mit Weihnachtsmann, blau", description: "HxBxT: 58cm x 36cm x 14cm" },
  { image: "pyramide-gross-weihnachtsmann-schwarz", name: "Große Pyramide mit Weihnachtsmann, schwarz" },
  { image: "pyramide-gross-weihnachtsmann-weiss", name: "Große Pyramide mit Weihnachtsmann, weiß", description: "HxBxT: 58cm x 36cm x 14cm" },
  { image: "pyramide-klein-schwarz", name: "Kleine Pyramide, schwarz" },
  { image: "pyramide-klein-weihnachtsmann-blau", name: "Kleine Pyramide mit Weihnachtsmann, blau", description: "HxBxT: 28cm x 17cm x 10cm" },
  { image: "pyramide-klein-weihnachtsmann-gruen", name: "Kleine Pyramide mit Weihnachtsmann, grün" },
  { image: "pyramide-klein-weihnachtsmann-weiss-bemalt", name: "Kleine Pyramide mit bemaltem Weihnachtsmann, weiß" },
  { image: "pyramide-klein-weihnachtsmann-weiss", name: "Kleine Pyramide mit Weihnachtsmann, weiß", description: "HxBxT: 28cm x 17cm x 10cm" },
  { image: "pyramide-klein-weiss", name: "Kleine Pyramide, weiß" },
  { image: "raben", name: "Raben", description: "Höhe: 17,5 cm" },
  { image: "schwibbogen", name: "Schwibbogen" },
  { image: "spieluhr-haus-boot-hai", name: "Spieluhr mit Insel, Boot und Hai" },
  { image: "spieluhr-haus-hai-gross", name: "Spieluhr mit Insel und Hai, gross", video: true },
  { image: "spieluhr-haus-hai-klein", name: "Spieluhr mit Insel und Hai, klein", video: true },
  { image: "spieluhr-haus-klein-rot", name: "Kleine Spieluhr mit rotem Haus", video: true },
  { image: "spieluhr-haus-klein-weiß-teich", name: "Kleine Spieluhr mit weißem Haus und Teich" },
  { image: "spieluhr-haus-klein-weiß", name: "Kleine Spieluhr mit weißem Haus", video: true },
  { image: "spieluhr-haus-tiere", name: "Spieluhr mit Haus und Tieren", description: "22cm Durchmesser, 28cm hoch" },
  { image: "spieluhr-haus-unbemalt", name: "Kleine Spieluhr mit Haus, unbemalt", video: true },
  { image: "spieluhr-katze", name: "Kleine Spieluhr mit Katze" },
  { image: "spieluhr-leuchtturm", name: "Spieluhr mit Leuchtturm", video: true },
  { image: "spieluhr-maus-kaese", name: "Spieluhr mit Maus und Käse", video: true },
  { image: "spieluhr-muell-mit-fenster", name: "Spieluhr mit Müllplatz, Variante 2", video: true },
  { image: "spieluhr-muell", name: "Spieluhr mit Müllplatz, Variante 1", video: true },
  { image: "spieluhr-palme", name: "Spieluhr mit Palmeninsel", video: true },
  { image: "weihnachtsmann-gross", name: "Großer Weihnachtsmann" },
  { image: "weihnachtsmann-klein", name: "Kleiner Weihnachtsmann", description: "Höhe: 14,5 cm" },
];

function shuffle(array: any[]) {
  for (var i = array.length - 1; i > 0; i--) {
    var j = Math.floor(Math.random() * (i + 1));
    var temp = array[i];
    array[i] = array[j];
    array[j] = temp;
  }
}

shuffle(products);
