import "./index.scss";
import React, { useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom/client";
import { NavLink, Route, Routes, HashRouter, Outlet, useParams, useLocation, Navigate } from "react-router-dom";
import Masonry from "@mui/lab/Masonry";
import { ProductData, products } from "./products";
import { Datenschutz } from "datenschutz/datenschutz";
import { Helmet } from "react-helmet";

function Root() {
  const scrollContainer = useRef(null);
  const location = useLocation();
  const [scrollTop, setScrollTop] = useState<number>(0);
  const [isProductPage, setIsProductPage] = useState<boolean>(false);

  useEffect(() => {
    const productName = location.pathname.match(/objekte\/(.+)/)?.[1];
    const newIsProductPage = products.some((product) => product.image === productName);

    if (newIsProductPage !== isProductPage) {
      setIsProductPage(newIsProductPage);

      if (newIsProductPage) {
        const newScrollTop = window.scrollY;
        setScrollTop(newScrollTop);

        setTimeout(() => {
          (scrollContainer.current as any).scrollTop = newScrollTop;
        }, 0);
      }

      if (!newIsProductPage) {
        setTimeout(() => {
          window.scrollTo(0, scrollTop);
        }, 0);
      }
    }
  }, [location]);

  return (
    <div ref={scrollContainer} className={isProductPage ? "root noscroll" : "root"}>
      <div className="header">
        <h1>Holzzeug</h1>
        <div>
          <MainNavigationEntry to="objekte">Objekte</MainNavigationEntry> | <MainNavigationEntry to="kontakt">Kontakt</MainNavigationEntry>
        </div>
      </div>
      <div className="content">
        <Routes>
          <Route index element={<Navigate to="objekte" />} />
          <Route path="objekte/*" element={<Gallery products={products} />} />
          <Route path="kontakt" element={<Kontakt />} />
          <Route path="datenschutz" element={<Datenschutz />} />
        </Routes>
      </div>
      <div className="impressum">
        <span>Helmut Kaiser</span> | <span>Volbedingstraße 27, 04357 Leipzig</span> | +49 177 4929728 | <a href="mailto:info@kaiser-holzzeug.de">info@kaiser-holzzeug.de</a> |{" "}
        <NavLink to="/datenschutz">Datenschutz</NavLink>
      </div>
    </div>
  );
}

function MainNavigationEntry(props: React.PropsWithChildren<{ to: string }>) {
  return (
    <NavLink to={props.to} className={({ isActive }) => (isActive ? "navigation active" : "navigation")}>
      {props.children}
    </NavLink>
  );
}

function Kontakt() {
  const [response, setResponse] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [content, setContent] = useState<string>("");
  const [consent, setConsent] = useState<boolean>(false);

  const handleSubmit = (e: any) => {
    e.preventDefault();

    let formData = new FormData();
    formData.append("email", email);
    formData.append("content", content);

    fetch("/index.php", {
      method: "POST",
      body: formData,
    })
      .then((res) => {
        console.log("Request complete! response:", res);
        setResponse("Nachricht wurde erfolgreich gesendet.");
        setEmail("");
        setContent("");
      })
      .catch((error) => {
        console.error(error);
        setResponse("Nachricht konnte nicht gesendet werden.");
      });
  };

  return (
    <div className="kontakt">
      <Helmet>
        <title>Kontakt - Holzzeug</title>
      </Helmet>
      Für Anfragen aller Art bin ich unter{" "}
      <a className="highlight" href="mailto:info@kaiser-holzzeug.de">
        info@kaiser-holzzeug.de
      </a>{" "}
      oder unter <span className="highlight">+49 177 4929728</span> erreichbar, gerne kannst Du auch folgendes Kontaktformular verwenden:
      <br />
      <br />
      <form
        className="formular"
        onSubmit={(e) => {
          handleSubmit(e);
        }}
      >
        <label>Deine E-Mail-Adresse:</label>
        <input type="email" id="email" required name="email" value={email} onChange={(e) => setEmail(e.target.value)} />
        <label>Deine Anfrage:</label>
        <textarea id="content" required name="content" value={content} onChange={(e) => setContent(e.target.value)}></textarea>
        <span>
          <input type="checkbox" required value={consent.toString()} onChange={(e) => setConsent(e.target.value?.toLocaleLowerCase() === "true")} />
          Ich bin damit einverstanden, dass meine Daten zum Zweck der Bearbeitung meiner Anfrage entsprechend der <NavLink to="/datenschutz">Datenschutzvereinbarung</NavLink> gespeichert werden.
        </span>
        <input type="submit" id="submit" value="Absenden" />
      </form>
      <div className="response">{response}</div>
    </div>
  );
}

function Gallery(props: { products: ProductData[] }) {
  return (
    <>
      <Helmet>
        <title>Objekte - Holzzeug</title>
      </Helmet>
      <Routes>
        <Route index element={<ProductList products={props.products} />} />
        <Route
          path=":product"
          element={
            <>
              <ProductList products={props.products} />
              <ProductPage products={props.products} />
            </>
          }
        />
      </Routes>

      <Outlet />
    </>
  );
}

function ProductList(props: { products: ProductData[] }) {
  return (
    <Masonry columns={{ xs: 1, sm: 2, md: 3, lg: 4, xl: 5 }} spacing={2}>
      {props.products.map((x) => (
        <NavLink to={`/objekte/${x.image}`} key={x.image} className="image-box">
          <div>
            <img src={`/images/thumbnails/${x.image}.jpg`} className="thumbnail" />
            <img src={"play-button.svg"} width="90" height="90" className={x.video ? "show-play-button" : "hide-play-button"} />
          </div>
        </NavLink>
      ))}
    </Masonry>
  );
}

function ProductPage(props: { products: ProductData[] }) {
  const productParam = useParams().product;
  const location = useLocation();

  const productIndex = props.products.findIndex((x) => x.image === productParam);

  const backLink = location.pathname.replace(/(.*)\/.*/, "$1");
  let nextLink = "";
  let prevLink = "";
  if (productIndex !== -1) {
    if (productIndex < props.products.length - 1) {
      nextLink = `${backLink}/${props.products[productIndex + 1].image}`;
    }

    if (productIndex > 0) {
      prevLink = `${backLink}/${props.products[productIndex - 1].image}`;
    }
  }

  const product = props.products[productIndex];
  if (!product) {
    return <></>;
  }

  return (
    <div id="overlay">
      <Helmet>
        <title>{product.name} - Holzzeug</title>
      </Helmet>

      <div className="details">
        {" "}
        {product.video ? <video loop muted autoPlay src={`/videos/${product.image}.mp4`} /> : <img src={`/images/${product.image}.jpg`} />}
        <div>
          <h1>{product.name}</h1>
          {product.description}
        </div>
      </div>

      <div className="product-navigation">
        <NavLink to={prevLink} className={prevLink ? "next-and-previous" : "next-and-previous disabled"}>
          ❮
        </NavLink>
        <NavLink to={nextLink} className={nextLink ? "next-and-previous" : "next-and-previous disabled"}>
          ❯
        </NavLink>
      </div>

      <NavLink to={backLink} className="close" />
    </div>
  );
}

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

root.render(
  <React.StrictMode>
    <HashRouter>
      <Root />
    </HashRouter>
  </React.StrictMode>
);
