import "./datenschutz.scss";
import { useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import contentPath from "./datenschutz-content.md";
import { Helmet } from "react-helmet";

export function Datenschutz() {
  const [content, setContent] = useState<string>("");
  useEffect(() => {
    fetch(contentPath)
      .then((x) => x.text())
      .then((x) => setContent(x));
  }, []);

  return (
    <>
      <Helmet>
        <title>Datenschutz - Holzzeug</title>

        <script src="https://kaiser-holzzeug.de/analytics/index.php?module=CoreAdminHome&action=optOutJS&divId=matomo-opt-out&language=auto&showIntro=0"></script>
      </Helmet>
      <div className="datenschutz">
        <ReactMarkdown children={content}></ReactMarkdown>

        <div id="matomo-opt-out"></div>
      </div>
    </>
  );
}
